import Vue from '@/index.js'
import './style.scss'

import page from '@/mixins/page'
export default new Vue({
  el: '#app',
  mixins: [page],
  components: {
    'layout-index-testimonials': () => import('@/components/layout/index/layout-index-testimonials'),
    'layout-index-carousel': () => import('@/components/layout/index/layout-index-carousel'),
    'layout-catalog-header': () => import('@/components/layout/catalog/layout-catalog-header.vue'),
    'layout-catalog-categories': () => import('@/components/layout/catalog/layout-catalog-categories.vue'),
    'layout-catalog-category-card': () => import('@/components/layout/catalog/layout-catalog-category-card.vue'),
    'layout-brands-carousel': () => import('@/components/layout/layout-brands-carousel.vue'),
    'layout-features': () => import('@/components/layout/layout-features.vue'),
    'layout-product-alts': () => import('@/components/layout/product/layout-product-alts.vue'),
    'layout-products-cached': () => import('@/components/layout/product/layout-products-cached.vue'),
    'layout-seo-blocks': () => import('@/components/layout/layout-seo-blocks.vue'),
    'layout-contacts-block': () => import('@/components/layout/contacts/layout-contacts-block'),
    'layout-index-steps': () => import('@/components/layout/index/layout-index-steps')
  }
})
